import { useEffect } from 'react';
import firebase, { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw'
import makeRequest from "../../app/libs/request";

// import "firebase/messaging";

const config = {
  // Firebase
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.MEASUREMENT_ID,
}
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getFCMToken = async (cb) => {
  try {
    const token = await getToken(messaging, {
      vapidKey: config.FIREBASE_VAPID_KEY,
    });
    if (token) {
      makeRequest("post", `user/notification/token/update`, { notificationToken: token }).then(({ data }) => {
        // console.log('data.......', data);
      })
        .catch((err) => {
          console.log('err.......', err);
        });
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload: ", payload)
      resolve(payload);
    });
  });

export default messaging;
