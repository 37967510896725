/* eslint-disable no-lone-blocks */
import React, { Suspense, lazy, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { AUTH_TOKEN_KEY } from "../../config/auth";
import makeRequest from "../../../app/libs/request";
{
	/* =============Dashboard============= */
}
const Dashboard = lazy(() => import("../dashboard/Dashboard"));

const Error403 = lazy(() => import("../common/Error403"));
{
	/* ==================================== */
}
const ListDepartment = lazy(() => import("../department/ListDepartment"));
const ListCourse = lazy(() => import("../course/ListCourse"));
const ViewCourse = lazy(() => import("../course/ViewCourse"));
const Analytics = lazy(() => import("../course/Analytics"));
const Attended = lazy(() => import("../course/Attended"));
const ListUserSub = lazy(() => import("../users/sub_user/ListUser"));
// const ListInvite = lazy(() => import("../users/sub_user/ListInvite"));
const ListMarker = lazy(() => import("../users/marker/ListUser"));
const CreateMarker = lazy(() => import("../users/marker/CreateUser"));
const EditMarker = lazy(() => import("../users/marker/EditUser"));
const CreateUserSub = lazy(() => import("../users/sub_user/CreateUser"));
const EditUserSub = lazy(() => import("../users/sub_user/EditUser"));
const ListAdmin = lazy(() => import("../admin/ListUser"));
const CreateAdmin = lazy(() => import("../admin/CreateUser"));
const EditAdmin = lazy(() => import("../admin/EditUser"));
const ListTranning = lazy(() => import("../trannings/ListTranning"));
const CreateTranning = lazy(() => import("../trannings/CreateTranning"));
const EditTranning = lazy(() => import("../trannings/EditTranning"));
const ListInquiry = lazy(() => import("../inquiry/ListInquiry"));
const ViewInquiry = lazy(() => import("../inquiry/ViewInquiry"));
const SubcriptionPlan = lazy(() => import("../home/SubcriptionPlan"));
const Payment = lazy(() => import("../payment/PaymentInfo"));
const UpdatePlan = lazy(() => import("../payment/UpdatePlan"));
const BillingList = lazy(() => import("../payment/BillingList"));
const UpdateCard = lazy(() => import("../payment/UpdateCard"));
const StorageDetail = lazy(() => import("../payment/StorageDetail"));
const MyCompany = lazy(() => import("../home/MyCompany"));
const Appearance = lazy(() => import("../website/Appearance"));
const Policy = lazy(() => import("../static-page/Policy"));
const Condition = lazy(() => import("../static-page/Condition"));
// const CompanyProfile = lazy(() => import("../website/CompanyProfile"));
const DashboardMarker = lazy(() => import("../dashboard/DashboardMarker"));
const DetailProgress = lazy(() => import("../dashboard/DetailProgress"));
const ExamResponse = lazy(() => import("../course/response/Exam"));
const DetailResponse = lazy(() => import("../course/response/Detail"));
const PreviewExam = lazy(() => import("../course/preview/PreviewExam"));
const ListRole = lazy(() => import("../roles/ListRole"));
const EditRole = lazy(() => import("../roles/EditRole"));
const CreateRole = lazy(() => import("../roles/CreateRole"));

export default function HomePage() {
	const [isAdmin, setIsAdmin] = useState(true);
	// const [role, setRole] = useState("");
	setTimeout(() => {
		if (localStorage.getItem(AUTH_TOKEN_KEY)) {
			let auth = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
			if (auth && auth.authToken) {
				const token = JSON.parse(auth.authToken);
				makeRequest("get", `user/userInfo?token=${token}`)
					.then(({ data }) => {
						if (data.signal) {
							setIsAdmin(data.data.is_manager);
							// setRole(data.data.role_admin);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	}, 500);
	if (isAdmin) {
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					{
						/* Redirect from root URL to /dashboard. */
						<Redirect exact from="/" to="/dashboard" />
					}
					{/* Route other */}
					<Route path="/subscription-plan" component={SubcriptionPlan} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/my-company" component={MyCompany} />
					{/* <Redirect to="Error403" /> */}
					<Route path="/Error403" component={Error403} />
					{/* <Redirect to="TestMe" /> */}
					<Route path="/courses/list" component={ListCourse} />
					<Route path="/course/view/:id" component={ViewCourse} />
					<Route path="/course/attendeed/:id" component={Attended} />
					<Route path="/course/analytics" component={Analytics} />

					<Route path="/users/list" component={ListUserSub} />
					{/* <Route path="/invite/list" component={ListInvite} /> */}
					<Route path="/users/create" component={CreateUserSub} />
					<Route path="/users/edit/:id" component={EditUserSub} />
					<Route path="/department/list" component={ListDepartment} />
					<Route path="/admin/list" component={ListAdmin} />
					<Route path="/admin/create" component={CreateAdmin} />
					<Route path="/admin/edit/:id" component={EditAdmin} />
					<Route path="/inquiry/list" component={ListInquiry} />
					<Route path="/inquiry/view/:id" component={ViewInquiry} />
					<Route path="/training/list" component={ListTranning} />
					<Route path="/training/create" component={CreateTranning} />
					<Route path="/training/edit/:id" component={EditTranning} />
					<Route path="/payment" component={Payment} />
					<Route path="/change-subscription-plan" component={UpdatePlan} />
					<Route path="/billing-activity" component={BillingList} />
					<Route path="/change-payment-method" component={UpdateCard} />
					<Route path="/storage-detail" component={StorageDetail} />
					<Route path="/appearance" component={Appearance} />
					<Route path="/policy" component={Policy} />
					<Route path="/condition" component={Condition} />
					<Route path="/marker/list" component={ListMarker} />
					<Route path="/marker/create" component={CreateMarker} />
					<Route path="/marker/edit/:id" component={EditMarker} />
					<Route path="/course/:id/preview-exam/:exam_id/version/:version_id" component={PreviewExam} />
					<Route path="/course/:id/user/:user_id" component={ExamResponse} />
					<Route path="/course/:id/user-data/:user_id/item/:progress_id" component={DetailResponse} />
					<Route path="/role/list" component={ListRole} />
					<Route path="/role/edit/:id" component={EditRole} />
					<Route path="/role/create" component={CreateRole} />

					{/* <Route path="/company-profile" component={CompanyProfile} /> */}
				</Switch>
			</Suspense>
		);
	} else
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					{
						/* Redirect from root URL to /dashboard. */
						<Redirect exact from="/" to="/dashboard" />
					}
					{/* Route other */}
					<Route path="/dashboard" component={DashboardMarker} />
					{/* <Redirect to="Error403" /> */}
					<Route path="/Error403" component={Error403} />
					<Route path="/detail-progress/:id" component={DetailProgress} />
				</Switch>
			</Suspense>
		);
}
