/* eslint-disable no-mixed-operators */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { connect } from 'react-redux'
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle'
import { Avatar } from 'antd'
import moment from 'moment'
import {
  checkNotiIsActive,
  getContentNoti,
} from '../../../_metronic/utils/notification'
import makeRequest from '../../libs/request'
import InfiniteScroll from './InfiniteScroll'

const NotificationCard = ({ value }) => {
  const isActive = checkNotiIsActive(value?.status)
  const noti = getContentNoti(value?.type, value)
  return (
    <Dropdown.Item style={{ padding: 0, whiteSpace: 'normal' }}>
      <Link
        onClick={() => {
          if (isActive) {
            makeRequest('post', `notification/status/update`, {
              id: value.id,
              status: 0,
            })
              .then(({ data }) => {
                // console.log('data.........', data)
              })
              .catch((err) => {
                console.log('++++++++++++++++', err)
              })
          }
        }}
        to={noti?.path ?? `/`}
      >
        <div
          className={`pl-16 pr-16 pt-16 customer-noti-link ${isActive ? 'bg-color-grey' : ''
            }`}
        >
          <div className='mb-8 d-flex justify-content-between align-items-center'>
            <div className='f-medium_24 pr-20' style={{
              maxWidth: "300px",
              lineBreak: "auto",
              wordWrap: "break-word",
              whiteSpace: "-moz-pre-wrap",
              whiteSpace: "pre-wrap",
            }}>{noti?.content ?? ''}</div>
            {isActive ? (
              <div
                style={{ minWidth: '8px', minHeight: '8px' }}
                className='primary-green-bg border-radius-99'
              />
            ) : (
              <></>
            )}
          </div>
          <div className='f-regular_20 color-grey-500 pb-8 border-bottom-default d-flex align-items-center'>
            <img
              src={'/images/notification-icon/menu-board.svg'}
              className='mr-10'
            />
            {value?.createdAt &&
              moment(value?.createdAt ?? new Date()).format('hh:mm DD/MM/YY')}
          </div>
        </div>
      </Link>
    </Dropdown.Item>
  )
}

class Notification extends React.Component {
  render() {
    const { notiList, setPageNoti, setReloadNoti } = this.props
    const readAllMark = () => {
      makeRequest('post', `notification/mark-all-as-read`)
        .then(({ data }) => {
          setReloadNoti(true)
        })
        .catch((err) => {
          console.log('++++++++++++++++', err)
        })
    }
    return (
      <Dropdown
        className='kt-header__topbar-item kt-header__topbar-item--user'
        drop='down'
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id='dropdown-toggle-user-profile'
        >
          <div
            className='kt-header__topbar-user-2 border-radius-50'
            data-toggle='tooltip'
            data-placement='top'
            title='Notification'
          >
            <div className='border-radius-99 d-flex justify-content-center align-items-center notification-icon'>
              <Avatar
                alt='Avatar'
                src={'/images/notification-icon/notification.svg'}
                style={{ width: '34px', height: '34px' }}
              />
              {notiList?.totalActive > 0 && (
                <div className='border-radius-99 noti-number'>
                  {notiList?.totalActive > 99
                    ? '99+'
                    : notiList?.totalActive
                      ? notiList?.totalActive
                      : 0}
                </div>
              )}
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl dropdown-menu-customer pb-5'>
          <div className='kt-user-card justify-content-between'>
            <div className='f-semibold_30'>Notification</div>
            <div className='f-medium_24-2 primary-color'>
              {notiList?.totalActive > 0 ? (
                <div className='d-flex cursor-pointer' onClick={readAllMark}>
                  <img
                    src={'/images/menu-icon/done-all.svg'}
                    alt='images.jpg'
                    className='mr-5'
                  />{' '}
                  Mark all as read
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='notification-customer'>
            {notiList?.list ? (
              <InfiniteScroll
                loader={<p className='f-regular_20'>Loading...</p>}
                className=''
                fetchMore={() => setPageNoti((prev) => (prev ?? 0) + 1)}
                hasMore={notiList.list.length < notiList.total}
                endMessage={
                  <p className='f-regular_20'>You have seen it all</p>
                }
              >
                {notiList?.list.map((item, key) => {
                  return <NotificationCard key={key} value={item} />
                })}
              </InfiniteScroll>
            ) : (
              <></>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default React.memo(Notification)
