import checkPermission from "../../app/libs/permission";

export default {
	header: {
		self: {},
		items: [],
	},
	aside: {
		self: {},
		items: [
			{
				title: "Review Test",
				root: true,
				image: "/images/menu-icon/icon-19.svg",
				page: "dashboard",
				permission: "marker",
				pathname: [
					{
						content: "Detail",
						path: "/detail-progress/:id",
					},
				],
			},
			{
				title: "Dashboard",
				root: true,
				page: "dashboard",
				translate: "MENU.DASHBOARD",
				permission: "dashboard.view",
				bullet: "dot",
				image: "/images/menu-icon/icon-18.svg",
				pathname: [
					{
						content: "Dashboard",
						path: "/dashboard",
					},
					{
						content: "Inquiry Management",
						path: "/inquiry/list",
					},
					{
						content: "Inquiry Detail",
						path: "/inquiry/view/:id",
					},
				],
			},
			{
				title: "Department Management",
				root: true,
				page: "department/list",
				image: "/images/menu-icon/icon-17.svg",
				permission: "department.view",
				pathname: [
					{
						content: "Department management",
						path: "/department/list",
					},
					{
						content: "Create Department",
						path: "/department/create",
					},
					{
						content: "Edit Department",
						path: "/training-matrix/edit/:id",
					},
				],
			},
			{
				title: "Course Management",
				root: true,
				image: "/images/menu-icon/icon-16.svg",
				bullet: "dot",
				permission: [
					"course",
					"course_assigned.view",
					"course_analytics.view",
				],
				submenu: [
					{
						title: "External Courses",
						page: "courses/list",
						permission: ["course_assigned.view"],
						pathname: [
							{
								content: "External Courses",
								path: "/courses/list",
							},
							{
								content: "External Courses",
								path: "/course/view/:id",
								breadcrumb: [
									{
										title: "External Courses",
										link: "/courses/list",
									},
									{
										title: "View Course",
										link: "",
									},
								],
							},
							{
								content: "Attendee",
								path: "/course/attendeed/:id",
							},
							{
								content: "Review Exam",
								path: "/course/:course/preview-exam/:exam/version/:version"
							},
							{
								content: "Detail Response Exam",
								path: "/course/:id/user-data/:user_id/item/:progress_id"
							},
							{
								content: "Response Exam",
								path: "/course/:id/user/:user_id"
							},
						],
					},
					{
						title: "Course Analytics",
						page: "course/analytics",
						permission: "course_analytics.view",
						pathname: [
							{
								content: "Course Analytics",
								path: "/course/analytics",
							}
						],
					},
				],
			},
			{
				title: "Program Management",
				root: true,
				image: "/images/menu-icon/icon-12.svg",
				page: "training/list",
				bullet: "dot",
				permission: "training.view",
				pathname: [
					{
						content: "Program Management",
						path: "/training/list",
					},
					{
						content: "Create Event",
						path: "/training/create",
					},
					{
						content: checkPermission("training.update") ? `Edit Event` : `Detail Event`,
						path: "/training/edit/:id",
					}
				],
			},
			{
				title: "User Management",
				root: true,
				image: "/images/menu-icon/icon-11.svg",
				bullet: "dot",
				permission: [
					"user_mngt",
					"role.view",
					"admin.view",
					"user.view",
					"marker.view",
				],
				submenu: [
					{
						title: "Admin",
						page: "admin/list",
						permission: "admin.view",
						pathname: [
							{
								content: "Admin Management",
								path: "/admin/list",
								breadcrumb: [
									{
										title: "Admin Management",
										link: "",
									}
								],
							},
							{
								content: "Create Admin",
								path: "/admin/create",
								breadcrumb: [
									{
										title: "Admin Management",
										link: "/admin/list",
									},
									{
										title: "Create Admin",
										link: "",
									},
								],
							},
							{
								content: "Edit Admin",
								path: "/admin/edit/:id",
								breadcrumb: [
									{
										title: "Admin Management",
										link: "/admin/list",
									},
									{
										title: "Edit Admin",
										link: "",
									},
								],
							},
						],
					},
					{
						title: "Users",
						page: "users/list",
						permission: "user.view",
						pathname: [
							{
								content: "User Management",
								path: "/users/list",
								breadcrumb: [
									{
										title: "User Management",
										link: "",
									}
								],
							},
							{
								content: "Create User",
								path: "/users/create",
								breadcrumb: [
									{
										title: "Users",
										link: "/users/list",
									},
									{
										title: "Create User",
										link: "",
									},
								],
							},
							{
								content: "Edit User",
								path: "/users/edit/:id",
								breadcrumb: [
									{
										title: "Users",
										link: "/users/list",
									},
									{
										title: "Edit User",
										link: "",
									},
								],
							},
						],
					},
					{
						title: "Role & Permission",
						page: "role/list",
						permission: "role.view",
						pathname: [
							{
								content: "Role & Permissions Management",
								path: "/role/list",
								breadcrumb: [
									{
										title: "Role & Permissions Management",
										link: "",
									}
								],
							},
							{
								content: "Create Role",
								path: "/role/create",
								breadcrumb: [
									{
										title: "List Role",
										link: "/role/list",
									},
									{
										title: "Create Role",
										link: "",
									},
								],
							},
							{
								content: "Edit Role",
								path: "/role/edit/:id",
								breadcrumb: [
									{
										title: "List Role",
										link: "/role/list",
									},
									{
										title: "Edit Role",
										link: "",
									},
								],
							},
						],
					},
					{
						title: "Markers",
						page: "marker/list",
						permission: "marker.view",
						pathname: [
							{
								content: "Marker Management",
								path: "/marker/list",
								breadcrumb: [
									{
										title: "Marker Management",
										link: "",
									}
								],
							},
							{
								content: "Create Marker",
								path: "/marker/create",
								breadcrumb: [
									{
										title: "Marker Management",
										link: "/marker/list",
									},
									{
										title: "Create Marker",
										link: "",
									},
								],
							},
							{
								content: "Edit Marker",
								path: "/marker/edit/:id",
								breadcrumb: [
									{
										title: "Marker Management",
										link: "/marker/list",
									},
									{
										title: "Edit Marker",
										link: "",
									},
								],
							},
						],
					},
				],
			},
			{
				title: "Setting",
				root: true,
				image: "/images/menu-icon/icon-1.svg",
				bullet: "dot",
				permission: [
					"department.view",
					"user_mngt",
					"admin.view",
					"user.view",
					"marker.view",
					"role.view",
					"payment.view",
					"website.view",
					"property.view",
					"industry.view",
					"level.view",
				],
				submenu: [
					{
						title: "LMS Appearance Configuration",
						bullet: "dot",
						permission: "website.view",
						page: "appearance",
						pathname: [
							{
								content: "LMS Appearance Configuration",
								path: "/appearance",
							},
						],
					},
					{
						title: "Payment",
						page: "payment",
						permission: "payment.view",
						pathname: [
							{
								content: "Payment Information",
								path: "/payment",
							},
							{
								content: "Update Subscription Plan",
								path: "/change-subscription-plan",
							},
							{
								content: "Update Payment method",
								path: "/change-payment-method",
							},
							{
								content: "Billing Activity",
								path: "/billing-activity",
							},
							{
								content: "Storage Details",
								path: "/storage-detail",
							},
							{
								content: "Choose your Subscription Plan",
								path: "/subscription-plan",
							},
						]
					},
				],
			},
			{
				title: "Terms and Conditions",
				root: true,
				image: "/images/menu-icon/icon-19.svg",
				bullet: "dot",
				permission: "page.view",
				submenu: [
					{
						title: "Privacy policy",
						page: "policy",
						permission: "page.view",
						pathname: [
							{
								content: "Privacy policy",
								path: "/policy",
							},
						]
					},
					{
						title: "Terms and Conditions",
						page: "condition",
						permission: "page.view",
						pathname: [
							{
								content: "Terms and Conditions",
								path: "/condition",
							},
						]
					},
				],
			},
		],
	},
};
