import axios from "axios";
import { URL_API } from "../config/url";

export const LOGIN_URL = "auth/login-sub";
export const LOGIN_FIRST_URL = "auth/login-first";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const FORGOT_PASSWORD_URL = "auth/forgotPassword";
export const CONFIRM_FORGOT_PASSWORD = "auth/confirmForgotPassword";
export const ACTIVE_ACCOUNT = "auth/activeAccount";
export const ACTIVE_ACCOUNT_MARKER = "auth/activeAccountMarker";

export const ME_URL = "user/userInfo";

export function loginFirstTime(email, password, type, show_partner) {
	return axios.post(URL_API + LOGIN_FIRST_URL, { email, password, type, show_partner });
}

export function login(email, password, type) {
	return axios.post(URL_API + LOGIN_URL, { email, password, type });
}
export function forgotPassword(email) {
	return axios.post(URL_API + FORGOT_PASSWORD_URL, { email });
}

export function confirmForgotPassword(strConfirm, newPassword) {
	return axios.post(URL_API + CONFIRM_FORGOT_PASSWORD, { strConfirm, newPassword });
}

export function activeAccount(strConfirm, newPassword) {
	return axios.post(URL_API + ACTIVE_ACCOUNT, { strConfirm, newPassword });
}
export function activeAccountMarker(data) {
	return axios.post(URL_API + ACTIVE_ACCOUNT_MARKER, data);
}

export function register(email, fullname, username, password) {
	return axios.post(URL_API + REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
	return axios.post(URL_API + REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token) {
	return axios.get(URL_API + ME_URL + "?token=" + token);
}
