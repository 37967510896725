/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleLeftIcon } from "../assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../assets/layout-svg-icons/Angle-double-right.svg";

class Brand extends React.Component {
	ktToggleRef = React.createRef();

	componentDidMount() {
		// eslint-disable-next-line no-undef
		new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
	}

	render() {
		let text = "";
		let logo = "";
		let favicon = "";
		let site_name;
		let { setting } = this.props;
		if (setting) {
			text = setting.item.name;
			logo = setting.item.logo;
			favicon = setting.item.favicon ? JSON.parse(setting.item.favicon).active : "";
			site_name = setting.item.name;
		}
		if (text) {
			document.title = `${text} management system`;
			const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
			link.type = "image/x-icon";
			link.rel = "shortcut icon";
			link.href = favicon || logo;
			document.getElementsByTagName("head")[0].appendChild(link);
		} else {
			document.title = `CE Management system`;
		}
		return (
			<div className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`} id="kt_aside_brand">
				{/* <div className="kt-aside__brand-logo" style={{ width: "50%", height: "100%", margin: " 0 auto" }}>
					<Link to="/dashboard" style={{ textAlign: "center" }}>
						<img src={logo} width="50%" style={{ height: "100%", width: "100%", paddingTop: 3 }} />
					</Link>
				</div> */}
				<div className="kt-aside__brand-logo" style={{ margin: "0 auto" }}>
					<Link to="/dashboard" style={{ fontSize: "20px", color: "#fff", fontWeight: "bold", textAlign: "center" }}>
						{site_name.toUpperCase()}
					</Link>
				</div>
				{this.props.asideSelfMinimizeToggle && (
					<div className="kt-aside__brand-tools">
						<button ref={this.ktToggleRef} className="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
							<span>
								<AngleDoubleLeftIcon />
							</span>
							<span>
								<AngleDoubleRightIcon />
							</span>
						</button>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		brandClasses: builder.selectors.getClasses(store, {
			path: "brand",
			toString: true,
		}),
		asideSelfMinimizeToggle: objectPath.get(store.builder.layoutConfig, "aside.self.minimize.toggle"),
		headerLogo: builder.selectors.getLogo(store),
		headerStickyLogo: builder.selectors.getStickyLogo(store),
		toggleOptions: {
			target: "body",
			targetState: "kt-aside--minimize",
			togglerState: "kt-aside__brand-aside-toggler--active",
		},
	};
};

export default connect(mapStateToProps)(Brand);
