import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { InputLabel, TextField, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { activeAccountMarker } from "../../crud/auth.crud";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";

function ActiveMarker(props) {
	const [isRequested, setRequested] = useState(false);
	const { intl } = props;
	const passRef = React.createRef();
	const rePassRef = React.createRef();
	const tagRef = React.createRef();
	const firstNameRef = React.createRef();
	const lastNameRef = React.createRef();
	const companyRef = React.createRef();
	const mobileRef = React.createRef();
	const [dataAdd, setData] = useState({});
	const [styleLine, setStyleLine] = useState(false);
	if (isRequested) {
		return <Redirect to="/auth" />;
	}

	const onChangeMobile = (mobile) => {
		if (mobile === undefined) mobile = "";
		setData({
			...dataAdd,
			mobile,
		});
	};
	return (
		<div
			className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
			style={{ paddingTop: 185 }}
		>
			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title-2 mb-40">
						<h3>Complete Your Profile</h3>
					</div>

					<Formik
						initialValues={{ first_name: "", last_name: "", company: "", mobile: "", password: "", rePassword: "" }}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							if (!values.first_name || (values.first_name && !values.first_name.trim())) {
								setSubmitting(false);
								firstNameRef.current.focus();
								return showErrorMessage("Please enter first name.");
							} else {
								if (values.first_name.length > 50) {
									setSubmitting(false);
									firstNameRef.current.focus();
									return showErrorMessage("First name must be longer than 50 characters.");
								}
							}
							if (!values.last_name || (values.last_name && !values.last_name.trim())) {
								setSubmitting(false);
								lastNameRef.current.focus();
								return showErrorMessage("Please enter last name.");
							} else {
								if (values.last_name.length > 50) {
									setSubmitting(false);
									lastNameRef.current.focus();
									return showErrorMessage("Last name must be longer than 50 characters.");
								}
							}
							if (!values.company || (values.company && !values.company.trim())) {
								setSubmitting(false);
								companyRef.current.focus();
								return showErrorMessage("Please enter company name.");
							} else {
								if (values.company.length > 100) {
									setSubmitting(false);
									companyRef.current.focus();
									return showErrorMessage("Company name may not be greater than 100 characters.");
								}
							}
							if (!values.tag || (values.tag && !values.tag.trim())) {
								setSubmitting(false);
								tagRef.current.focus();
								return showErrorMessage("Please enter tag name.");
							} else {
								if (values.tag.length > 4) {
									setSubmitting(false);
									tagRef.current.focus();
									return showErrorMessage("Tag may not be greater than 4 characters.");
								}
							}
							if (dataAdd.mobile) {
								setSubmitting(false);
								let valid = dataAdd.mobile && isValidPhoneNumber(dataAdd.mobile) ? "true" : "false";
								if (valid === "false") {
									return showErrorMessage("Please enter a valid phone number.");
								}
							} else {
								setSubmitting(false);
								mobileRef.current.focus();
								return showErrorMessage("Please enter phone number.");
							}
							if (!values.password) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Please enter password.");
							} else {
								if (values.password.length < 6) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be at least 6 characters.");
								}
								if (values.password.length > 20) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be longer than 20 characters.");
								}
								const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
								if (!regex.test(values.password)) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage(
										"Password contains at least a upper case, lower case, symbol and a number. Except space character"
									);
								}
							}
							if (!values.rePassword) {
								setSubmitting(false);
								rePassRef.current.focus();
								return showErrorMessage("Please confirm password.");
							}

							if (values.password !== values.rePassword) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Invalid. Password and re-password do not match.");
							}

							// let email = props.match.params.email;
							let strConfirm = props.match.params.strConfirm;

							activeAccountMarker({
								...values,
								strConfirm,
								first_name: values.first_name.trim(),
								last_name: values.last_name.trim(),
								company: values.company.trim(),
							})
								.then(({ data }) => {
									if (data.signal) {
										showSuccessMessageIcon("Update success.");
										props.login(data.data);
									} else {
										setRequested(false);
										return showErrorMessage("Opps! Something was wrong!");
									}
								})
								.catch(() => {
									setSubmitting(false);
									setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.password }));
								});
						}}
					>
						{({ values, status, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form onSubmit={handleSubmit} className="kt-form-2">
								{status && (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								)}
								<div style={{ display: "flex" }}>
									<div className="form-group w-100" style={{ marginRight: 5 }}>
										<InputLabel>First Name</InputLabel>
										<TextField
											variant="outlined"
											size="small"
											type="text"
											margin="normal"
											className="kt-width-full mt-0"
											name="first_name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.first_name}
											autoFocus
											inputRef={firstNameRef}
										/>
									</div>
									<div className="form-group w-100">
										<InputLabel>Last Name</InputLabel>
										<TextField
											variant="outlined"
											size="small"
											type="text"
											margin="normal"
											className="kt-width-full mt-0"
											name="last_name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.last_name}
											inputRef={lastNameRef}
										/>
									</div>
								</div>

								<div className="form-group">
									<InputLabel>Tag</InputLabel>
									<TextField
										type="text"
										variant="outlined"
										size="small"
										margin="normal"
										className="kt-width-full mt-0"
										name="tag"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.tag}
										inputRef={tagRef}
										helperText={
											<p
												style={{
													fontSize: "8pt",
													marginBottom: 0,
													marginLeft: -14,
												}}
											>
												This tag will be use for the course certification combination
											</p>
										}
									/>
								</div>
								<div className="form-group" style={{ display: "flex" }}>
									<div className="form-group w-100" style={{ marginRight: 5 }}>
										<InputLabel>Company</InputLabel>
										<TextField
											variant="outlined"
											size="small"
											type="text"
											margin="normal"
											className="kt-width-full mt-0"
											name="company"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.company}
											inputRef={firstNameRef}
										/>
									</div>
									<div style={{ width: "50%" }}>
										<InputLabel>Phone number</InputLabel>
										<PhoneInput
											style={{
												marginLeft: 5,
												display: "flex",
												borderBottom: styleLine ? "2px solid #5867dd" : "1px solid #949494",
												height: 36,
												background: "#fff",
											}}
											international
											countryCallingCodeEditable={false}
											className="form-control"
											flags={flags}
											placeholder="Enter phone number"
											defaultCountry="SG"
											value={dataAdd.mobile}
											onChange={onChangeMobile}
											ref={mobileRef}
											error={
												dataAdd.mobile ? (isValidPhoneNumber(dataAdd.mobile) ? undefined : "Invalid phone number") : ""
											}
											onFocus={() => {
												setStyleLine(true);
											}}
											onBlur={() => {
												setStyleLine(false);
											}}
										/>
									</div>
								</div>
								<div className="form-group" style={{ display: "flex" }}>
									<div className="form-group w-100" style={{ marginRight: 5 }}>
										<InputLabel>Password</InputLabel>
										<TextField
											variant="outlined"
											size="small"
											type="password"
											margin="normal"
											className="kt-width-full mt-0"
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.password}
											inputRef={passRef}
										/>
									</div>
									<div className="form-group w-100">
										<InputLabel>Re-password</InputLabel>
										<TextField
											variant="outlined"
											size="small"
											type="password"
											margin="normal"
											className="kt-width-full mt-0"
											name="rePassword"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.rePassword}
											inputRef={rePassRef}
										/>
									</div>
								</div>

								<div className="form-group"></div>

								<div className="kt-login__actions" style={{ justifyContent: "center" }}>
									{/* <Link to="/auth">
										<button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
											Back
										</button>
									</Link> */}

									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isSubmitting}
										className="btn-signin"
										style={{
											width: "125px",
											height: "40px",
										}}
									>
										Submit
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(ActiveMarker));
